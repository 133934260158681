<template>
  <div class='qts-date-picker'>
    <div class="wrapper">
      <div class="header">
        <span class="title">{{ $t('general.quickTicketSelector.datePicker.title') }}</span>
        <span class="subtitle">{{ $t('general.quickTicketSelector.datePicker.subtitle') }}</span>
      </div>

      <base-date-picker
        v-if="dateRange"
        :defaultValue="new Date(selectedDateFromStore)"
        :dateRange="dateRange"
        @picked="handleDateSelection"
      />

      <div class="selected-day">
        <span class="label">{{ $t('general.quickTicketSelector.datePicker.selectedDay') }}</span>
        <span class="date">{{ selectedDateFromStore | moment('YYYY.MM.DD.') }}</span>
        <span class="day-name">{{ selectedDateFromStore | moment('dddd') }}</span>
      </div>
      <base-button
        :isDisabled="selectedDateFromStore === undefined"
        :isSecondary="true" :text="$t('general.quickTicketSelector.datePicker.button.submit')"
        @clicked="handleButtonClick"
      />
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions } from 'vuex';

import BaseDatePicker from '@/components/shared/elements/inputs/BaseDatePicker';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'QuickDatePicker',
  props: {},
  components: {
    BaseDatePicker,
    BaseButton,
  },
  data: () => ({
    selectedDate: undefined,
    dateRange: undefined,
  }),
  created() {
    this.getPurchaseDateRange().then((resp) => {
      this.dateRange = resp.data;
    });
  },
  computed: {
    selectedDateFromStore() {
      return store.getters.getSelectedDate;
    },
  },
  methods: {
    ...mapActions({
      saveSelectedDate: 'cart/saveSelectedDate',
      getPurchaseDateRange: 'settings/getPurchaseDateRange',
    }),
    handleDateSelection(val) {
      this.saveSelectedDate(val);
    },
    handleButtonClick() {
      if (this.selectedDateFromStore !== undefined) {
        this.$emit('buttonClicked');
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  .title,
  .subtitle {
    display: block;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    color: $primaryBlue;
    text-transform: uppercase;
    line-height: 1;

    @media screen and (max-width: $breakpointDownSm) {
      font-size: 16px;
    }
  }

  .subtitle {
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    margin: 5px 0 0;
  }
}

.wrapper {
  text-align: center;
}

.base-date-picker {
  max-width: 320px;
  padding: 0;
  margin: 20px auto;
}

.selected-day {
  .label {
    font-size: 10px;
    font-weight: 500;
    display: block;
    margin: 0 0 5px;
  }

  .date,
  .day-name {
    display: block;
    color: $primaryBlue;
    font-weight: 700;
    line-height: 1.2;
  }

  .date {
    font-size: 20px;

    @media screen and (max-width: $breakpointDownSm) {
      font-size: 16px;
    }
  }

  .day-name {
    font-size: 12px;
    text-transform: uppercase;
  }
}

.button-base {
  margin: 12px auto 0;
}

</style>
